<template>
  <div class="AuxiliaryBlank" :style="customStyle()">
  </div>
</template>

<script>
export default {
 name: 'AuxiliaryBlank',
  props:{
    Color:{
      type:String,
      default:"#FFFFFF"
    },
    Height:{
      type:Number,
      default:15
    }

  },
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {

    }
  },
   /**计算属性  */
  computed: {
  },
  /**  方法集合  */
  methods: {
    /**    */
    customStyle(){
      return{
      'background-color':this.Color,
      'height':this.Height + 'px',
      }
    
    },
  },
  /** 监听数据变化   */
  watch: {},
  /**  实例创建完成之后  */
  created() {},
  /**  实例被挂载后调用  */
  mounted() {},
}
</script>

<style lang="scss">

.AuxiliaryBlank{

 }
</style>
